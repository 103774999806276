import React from 'react';

import { Box, Button, CircularProgress } from '@material-ui/core';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { useFormStyles } from 'theme/form/form.styles';
import * as yup from 'yup';

import Form from 'components/@form/BaseForm';
import TextFormField from 'components/@form/TextFormField';
import Link from 'components/@navigation/Link';

interface EmailLoginProps {
  onSubmit: (values: FormData) => void;
  isLoading: boolean;
}

enum FormFields {
  email = 'email',
  password = 'password',
}

type FormData = {
  email: string;
  password: string;
};

const schema = (intl: IntlShape) =>
  yup.object().shape({
    [FormFields.email]: yup
      .string()
      .required(intl.formatMessage({ id: 'login.form.errors.required' }))
      .email(intl.formatMessage({ id: 'login.form.errors.no_email' })),
    [FormFields.password]: yup
      .string()
      .required(intl.formatMessage({ id: 'login.form.errors.required' })),
  });

const EmailLogin = ({ onSubmit, isLoading }: EmailLoginProps) => {
  const intl = useIntl();
  const classes = useFormStyles();

  return (
    <Form autoComplete="off" validationSchema={schema(intl)} onSubmit={onSubmit}>
      <TextFormField
        name={FormFields.email}
        fullWidth
        label={intl.formatMessage({ id: 'login.form.field.email.name' })}
        placeholder={intl.formatMessage({
          id: 'login.form.field.email.placeholder',
        })}
      />
      <TextFormField
        name={FormFields.password}
        fullWidth
        label={intl.formatMessage({ id: 'login.form.field.password.name' })}
        placeholder={intl.formatMessage({
          id: 'login.form.field.password.placeholder',
        })}
        type="password"
        hideValidationCheckmark
      />

      <Box width="100%" display="flex" justifyContent="flex-end">
        <Link variant="body2" href="#">
          <FormattedMessage id="login.form.actions.forgot_password" />
        </Link>
      </Box>

      <Button
        className={classes.button}
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        disabled={isLoading}
      >
        {isLoading ? (
          <CircularProgress size={24} />
        ) : (
          <FormattedMessage id="login.form.actions.login" />
        )}
      </Button>

      <Box my={4} display="flex" width="100%" flexDirection="column" alignItems="center">
        <Link className={classes.link} variant="body1" href="#">
          <FormattedMessage id="login.form.actions.email_code" />
        </Link>

        <Link className={classes.link} variant="body1" href="#">
          <FormattedMessage id="login.form.actions.no_account" />
        </Link>
      </Box>
    </Form>
  );
};

export default EmailLogin;
