import React from 'react';

import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  DeepPartial,
  FieldValues,
  FormProvider,
  SubmitHandler,
  UnpackNestedValue,
  useForm,
  ValidationMode,
} from 'react-hook-form';
import * as Yup from 'yup';

type Props<TFieldValues extends FieldValues = FieldValues> = Omit<
  React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>,
  'onSubmit'
> & {
  defaultValues?: UnpackNestedValue<DeepPartial<TFieldValues>>;
  children: React.ReactNode;
  onSubmit?: SubmitHandler<TFieldValues>;
  validationSchema?: Yup.ObjectSchema<FieldValues>;
  enableDevTools?: boolean;
  id?: string;
  validationMode?: keyof ValidationMode;
  disableGutters?: boolean;
};

type StyleProps = {
  disableGutters: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  root: ({ disableGutters }) => ({
    padding: theme.spacing(disableGutters ? 0 : 4),
    width: '100%',
  }),
}));

const Form = <TFieldValues extends FieldValues = FieldValues>({
  defaultValues,
  children,
  onSubmit = () => {},
  validationSchema,
  enableDevTools,
  validationMode = 'onBlur',
  id,
  className,
  disableGutters = false,
  ...rest
}: Props<TFieldValues>) => {
  const methods = useForm<TFieldValues>({
    defaultValues,
    mode: validationMode,
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
  });
  const classes = useStyles({ disableGutters });

  return (
    <>
      <FormProvider {...methods}>
        <form
          className={className ?? classes.root}
          noValidate
          onSubmit={methods.handleSubmit(onSubmit)}
          id={id}
          {...rest}
        >
          {children}
        </form>
      </FormProvider>
      {enableDevTools && <DevTool placement="top-right" control={methods.control} />}
    </>
  );
};

export default Form;
