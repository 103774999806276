import React from 'react';

import { Box, Button, CircularProgress } from '@material-ui/core';
import { isValidPhoneNumber } from 'libphonenumber-js/mobile';
import { formatIncompletePhoneNumber } from 'libphonenumber-js/mobile';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { useFormStyles } from 'theme/form/form.styles';
import * as yup from 'yup';

import Form from 'components/@form/BaseForm';
import TextFormField from 'components/@form/TextFormField';
import Link from 'components/@navigation/Link';

interface MobileLoginProps {
  isLoading: boolean;
  onSubmit: (values: FormData) => void;
}

enum FormFields {
  mobile = 'mobile',
}

type FormData = {
  mobile: string;
};

const schema = (intl: IntlShape) =>
  yup.object().shape({
    [FormFields.mobile]: yup
      .string()
      .required(intl.formatMessage({ id: 'login.form.errors.required' }))
      .test(
        'valid-mobile',
        intl.formatMessage({ id: 'login.form.errors.invalid_mobile' }),
        value => {
          return isValidPhoneNumber(value ?? '');
        },
      ),
  });

const MobileLogin = ({ onSubmit, isLoading }: MobileLoginProps) => {
  const intl = useIntl();
  const classes = useFormStyles();

  return (
    <Form autoComplete="off" validationSchema={schema(intl)} onSubmit={onSubmit}>
      <TextFormField
        name={FormFields.mobile}
        type="tel"
        formatValue={value => formatIncompletePhoneNumber(value)}
        fullWidth
        label={intl.formatMessage({ id: 'login.form.field.mobile.name' })}
        placeholder={intl.formatMessage({
          id: 'login.form.field.mobile.placeholder',
        })}
      />

      <Button
        className={classes.button}
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        disabled={isLoading}
      >
        {isLoading ? (
          <CircularProgress size={24} />
        ) : (
          <FormattedMessage id="login.form.actions.continue" />
        )}
      </Button>

      <Box my={4} display="flex" width="100%" flexDirection="column" alignItems="center">
        <Link className={classes.link} variant="body1" href="#">
          <FormattedMessage id="login.form.actions.no_account" />
        </Link>
      </Box>
    </Form>
  );
};

export default MobileLogin;
