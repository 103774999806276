import React, { useState } from 'react';

import { Box, makeStyles, Tab, Tabs, Typography } from '@material-ui/core';
import { Container } from '@material-ui/core';
import { parsePhoneNumber } from 'libphonenumber-js/mobile';
import _delay from 'lodash/delay';
import { NextPage } from 'next';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import { FormattedMessage, useIntl } from 'react-intl';
import SwipeableViews from 'react-swipeable-views';
import { Routes } from 'routes';

import EmailLogin from 'components/@login/EmailLogin';
import MobileLogin from 'components/@login/MobileLogin';
import TabPanel from 'components/@tabs/TabPanel';

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(8),
  },
}));

const Login: NextPage = () => {
  const classes = useStyles();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const intl = useIntl();

  const router = useRouter();

  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTabIndex(newValue);
  };

  const handleTabChangeIndex = (index: number) => {
    setActiveTabIndex(index);
  };

  const handleMobileSubmit = (data: { mobile: string }) => {
    const number = parsePhoneNumber(data.mobile);
    const msisdn = number.format('E.164');
    setLoading(true);
    _delay(() => {
      router.push(`${Routes.verificationCode}?msisdn=${encodeURIComponent(msisdn)}`);
    }, 1000);
  };

  const handleEmailSubmit = (_data: { email: string; password: string }) => {
    setLoading(true);
    _delay(() => router.push(Routes.onboarding), 1000);
  };

  return (
    <div>
      <NextSeo
        title={intl.formatMessage({ id: 'seo.title.login' })}
        //description={intl.formatMessage({ id: 'seo.description.login' })}
      />
      <Container maxWidth="sm" disableGutters>
        <Box mx={4}>
          <Typography className={classes.title} variant="h4" color="textPrimary">
            <FormattedMessage id="login.title" />
          </Typography>
        </Box>
        <Tabs value={activeTabIndex} onChange={handleTabChange} variant="fullWidth">
          <Tab label={<FormattedMessage id="login.tabs.phone" />} id="tab-0" />
          <Tab label={<FormattedMessage id="login.tabs.email" />} id="tab-1" />
        </Tabs>

        <Box my={6}>
          <SwipeableViews
            disableLazyLoading
            index={activeTabIndex}
            onChangeIndex={handleTabChangeIndex}
            // This line is a temporary fix https://github.com/oliviertassinari/react-swipeable-views/issues/599
            containerStyle={{
              transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s',
            }}
          >
            <TabPanel value={activeTabIndex} index={0}>
              <MobileLogin onSubmit={handleMobileSubmit} isLoading={isLoading} />
            </TabPanel>
            <TabPanel value={activeTabIndex} index={1}>
              <EmailLogin onSubmit={handleEmailSubmit} isLoading={isLoading} />
            </TabPanel>
          </SwipeableViews>
        </Box>
      </Container>
    </div>
  );
};

export default Login;
