import React from 'react';

interface Props {
  value: string | number;
  index: string | number;
}

const TabPanel: React.FC<Props> = ({ children, value, index, ...rest }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...rest}
    >
      {value === index && children}
    </div>
  );
};

export default TabPanel;
