import React from 'react';

import { InputAdornment, makeStyles, TextField, TextFieldProps } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { Controller } from 'react-hook-form';

export type TextFormFieldProps = TextFieldProps & {
  readOnly?: boolean;
  name: string;
  hideValidationCheckmark?: boolean;
  hideError?: boolean;
  formatValue?: (value: string) => string;
  apiError?: string;
};

const useStyles = makeStyles(theme => ({
  success: { color: theme.palette.success.main },
  field: { marginBottom: theme.spacing(5) },
}));

const TextFormField: React.FC<TextFormFieldProps> = ({
  placeholder,
  name,
  readOnly,
  disabled,
  required,
  variant = 'outlined',
  hideValidationCheckmark = false,
  hideError = false,
  formatValue,
  apiError,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Controller
      name={name}
      rules={{ required }}
      render={({ field: { ref, value = '', ...fieldProps }, fieldState: { error, isTouched } }) => {
        return (
          <TextField
            {...fieldProps}
            className={classes.field}
            id={name}
            {...rest}
            variant={variant}
            error={hideError ? false : !!error ? !!error : !!apiError}
            disabled={disabled || readOnly}
            helperText={hideError ? undefined : !!apiError ? apiError : error?.message}
            required={required}
            InputProps={
              !!error?.message && !hideValidationCheckmark
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <ErrorIcon color="error" />
                      </InputAdornment>
                    ),
                  }
                : isTouched && !hideValidationCheckmark
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <CheckCircleIcon className={classes.success} />
                      </InputAdornment>
                    ),
                  }
                : undefined
            }
            inputProps={{
              ...rest.inputProps,
              readOnly: readOnly,
              disabled: readOnly ? false : disabled, // hookfrom removes disabled values https://github.com/react-hook-form/react-hook-form/issues/2655
            }}
            value={formatValue ? formatValue(value) : value}
            inputRef={ref}
            placeholder={placeholder}
          />
        );
      }}
    />
  );
};

export default React.memo(TextFormField);
